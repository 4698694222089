/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import type { Billboard, PointPrimitive } from '@cesium/engine';
import {
    BillboardCollection,
    Cartesian2,
    Cartesian3,
    Color,
    GroundPrimitive,
    HorizontalOrigin,
    LabelCollection,
    LabelStyle,
    PointPrimitiveCollection,
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    VerticalOrigin,
} from '@cesium/engine';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import { useHandlePrimitiveClick } from '../Hooks/useHandlePrimitiveClick';
import { useRemoveRectangleGeometries } from '../Hooks/useRemoveRectangleGeometries';
import { useResetActiveCluster } from '../Hooks/useResetActiveCluster';
import { useResetActiveObject } from '../Hooks/useResetActiveObject';
import { useResetClusters } from '../Hooks/useResetClusters';
import { useUpdateBounds } from '../Hooks/useUpdateBounds';
import type {
    CesiumBillboard,
    ClusterProps,
    CustomBillboardType,
    HoveredBillboard,
    InternalBillboardCollection,
    ObjectPointProps,
} from '../Types/CustomTypes';
import type { ViewBounds } from './Clustering/Clustering';
import { arePositionsClose, findBoxCenter, getViewBounds } from './Clustering/Clustering';
import { ClusterInfo } from './Clustering/ClusterPointGroup';
import { EvoPoint } from './Individual/EvoPoint';
import { convertBoundingBox, SEARCH_BOUNDS } from './Utils/Bounds';
import { setImage } from './Utils/SetIconImage';

const myClusters: ClusterProps[] = [];

export const findClusters = (
    cesiumWidget: any,
    points: BillboardCollection,
    clusterPoints: PointPrimitiveCollection,
    labels: LabelCollection,
    billboardPositions: CustomBillboardType[],
    lastCameraPosition: Cartesian3,
    pointMap: Record<string, PointPrimitive>,
    selectedCluster: any = null,
) => {
    const { scene } = cesiumWidget;
    const { camera } = scene;

    // If the camera hasn't moved, don't update the clusters.
    const currentPosition = camera.position.clone();
    if (
        lastCameraPosition.x === currentPosition.x &&
        lastCameraPosition.y === currentPosition.y &&
        lastCameraPosition.z === currentPosition.z
    ) {
        return;
    }
    lastCameraPosition.x = currentPosition.x;
    lastCameraPosition.y = currentPosition.y;
    lastCameraPosition.z = currentPosition.z;

    labels.removeAll();
    billboardPositions.forEach((billboard, index) => {
        billboard.isVisible = true;
        if (points.get(index)) {
            points.get(index).show = true;
        }
    });

    const clusters: { id: string; center: Cartesian3; count: number; points: Billboard[] }[] = [];

    billboardPositions.forEach((billboard, index) => {
        if (!billboard.isVisible) return;

        let count = 1;
        const clusteredPositions: number[] = [index];

        billboardPositions.forEach((otherBillboard, otherIndex) => {
            if (
                index !== otherIndex &&
                otherBillboard.isVisible &&
                arePositionsClose(billboard.position, otherBillboard.position, scene, 120)
            ) {
                count += 1;
                otherBillboard.isVisible = false;
                clusteredPositions.push(otherIndex);
            }
        });

        if (count > 1) {
            let sumX = 0;
            let sumY = 0;
            let sumZ = 0;
            const clusteredPoints: Billboard[] = [];
            clusteredPositions.forEach((clusteredIndex) => {
                const { position } = billboardPositions[clusteredIndex];
                sumX += position.x;
                sumY += position.y;
                sumZ += position.z;

                const point = points.get(clusteredIndex);
                if (point) {
                    point.show = false;
                    clusteredPoints.push(point);
                }
            });

            const centerPosition = new Cartesian3(sumX / count, sumY / count, sumZ / count);

            clusters.push({
                id: centerPosition.toString(),
                center: centerPosition,
                count,
                points: clusteredPoints,
            });
            myClusters.push({
                id: centerPosition.toString(),
                center: centerPosition,
                count,
                points: clusteredPoints,
            });
        }
    });

    // Remove clusters that are no longer visible.
    const clusterIDs = clusters.map((cluster) => cluster.center.toString());

    if (selectedCluster) {
        const selectedClusterIndex = clusters.findIndex(
            (cluster) => cluster.id === selectedCluster.id,
        );
        if (selectedClusterIndex !== -1) {
            clusters.splice(selectedClusterIndex, 1);
        }
    }

    Object.keys(pointMap).forEach((key: string) => {
        if (!clusterIDs.includes(key)) {
            clusterPoints.remove(pointMap[key]);
            delete pointMap[key];
        }
    });

    clusters.forEach((cluster) => {
        const pointSize = 50;
        const clusterID = cluster.center.toString();

        // Add the point if it doesn't already exist.
        let point = null;
        if (pointMap[clusterID]) {
            point = pointMap[clusterID];
        } else {
            point = clusterPoints.add({
                position: cluster.center,
                pixelSize: pointSize,
                color: 'white',
                show: true,
                id: clusterID,
                eyeOffset: new Cartesian3(0, 0, -5), // Ensure points are rendered on top
            });
            pointMap[cluster.center.toString()] = point;
        }

        // Add the new label, only showing if the point is shown.
        labels.add({
            position: cluster.center,
            text: cluster.count.toString(),
            font: '16px sans-serif',
            fillColor: Color.BLACK,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE,
            verticalOrigin: VerticalOrigin.CENTER,
            horizontalOrigin: HorizontalOrigin.CENTER,
            pixelOffset: new Cartesian2(0, 0),
            scale: 1.0,
            showBackground: true,
            backgroundPadding: new Cartesian2(7, 5),
            backgroundColor: Color.WHITE,
            show: point.show,
            id: clusterID,
            eyeOffset: new Cartesian3(0, 0, -5), // Ensure labels are rendered on top
        });
    });

    return clusters;
};

export const ObjectPoint = ({ cesiumWidget, isDrawing, flyToInProgress }: ObjectPointProps) => {
    const [, setViewBounds] = useState<ViewBounds | null>(null);
    const { objects, setSearchBounds, setActiveObject, activeObject } = useDiscoverContext();
    const [showActiveObject, setShowActiveObject] = useState(false);

    const [hoveredBillboards, setHoveredBillboards] = useState<HoveredBillboard[]>([]);
    const currentHoveredIndexRef = useRef<string | null>(null);
    const billboardPositionsRef = useRef<any[]>([]);
    const handlerRef = useRef<ScreenSpaceEventHandler | null>(null);
    const [selectedCluster, setSelectedCluster] = useState<any | null>(null);
    const pointsRef = useRef<BillboardCollection | null>(
        null,
    ) as React.MutableRefObject<InternalBillboardCollection | null>;
    const [clusterReset, setClusterReset] = useState(false);

    useEffect(() => {
        if (activeObject && selectedCluster === null) {
            setShowActiveObject(true);
        } else {
            setShowActiveObject(false);
        }
    }, [activeObject, selectedCluster]);

    const throttledUpdateBounds = useUpdateBounds(cesiumWidget, setViewBounds, setSearchBounds);

    const billboardPositions = useMemo(
        () =>
            objects
                .map((object) => {
                    if (!object.geojson_bounding_box) return null;
                    const position = Cartesian3.fromDegrees(
                        findBoxCenter(object.geojson_bounding_box.coordinates[0]).lng,
                        findBoxCenter(object.geojson_bounding_box.coordinates[0]).lat,
                        0,
                    );
                    return {
                        position,
                        isVisible: true,
                        data: {
                            logitude: findBoxCenter(object.geojson_bounding_box.coordinates[0]).lng,
                            latitude: findBoxCenter(object.geojson_bounding_box.coordinates[0]).lat,
                            id: object.object_id,
                            schemaName: object.schema,
                        },
                    };
                })
                .filter(Boolean) as CustomBillboardType[],
        [objects],
    );

    useEffect(() => {
        if (!cesiumWidget) return;

        const points = new BillboardCollection();
        const clusterPoints = new PointPrimitiveCollection();
        const labels = new LabelCollection();

        pointsRef.current = points as InternalBillboardCollection;
        billboardPositionsRef.current = billboardPositions;

        billboardPositions.forEach((billboard) => {
            points.add({
                position: billboard.position,
                id: billboard.data,
                scale: 0.4,
                image: setImage(billboard.data.schemaName),
                verticalOrigin: VerticalOrigin.CENTER,
                horizontalOrigin: HorizontalOrigin.CENTER,
            });
        });

        const lastCameraPosition = new Cartesian3();
        const pointMap: Record<string, PointPrimitive> = {};

        const findClustersCallback = () =>
            findClusters(
                cesiumWidget,
                points,
                clusterPoints,
                labels,
                billboardPositions,
                lastCameraPosition,
                pointMap,
                selectedCluster,
            );

        cesiumWidget.scene.primitives.add(points);
        cesiumWidget.scene.primitives.add(clusterPoints);
        cesiumWidget.scene.primitives.add(labels);

        cesiumWidget.scene.postRender.addEventListener(findClustersCallback);
        setClusterReset(false);

        throttledUpdateBounds(isDrawing);

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.primitives.remove(points);
                cesiumWidget.scene.primitives.remove(clusterPoints);
                cesiumWidget.scene.primitives.remove(labels);
                cesiumWidget.scene.postRender.removeEventListener(findClustersCallback);
            }
        };
    }, [cesiumWidget, billboardPositions, throttledUpdateBounds, isDrawing, clusterReset]);

    const updateScreenPositions = () => {
        setHoveredBillboards((prev) =>
            prev.map((billboard) => {
                const billboardPosition = billboardPositionsRef.current.find(
                    (b) => b.data.id === billboard.data.id,
                );
                if (billboardPosition) {
                    return {
                        ...billboard,
                        screenPosition: cesiumWidget!.scene.cartesianToCanvasCoordinates(
                            billboardPosition.position,
                            new Cartesian2(),
                        ),
                    };
                }
                return billboard;
            }),
        );
    };

    const resetClusters = useResetClusters(cesiumWidget!, setClusterReset);

    useEffect(() => {
        if (!cesiumWidget) return;

        handlerRef.current = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        const cameraMoveListener = () => {
            updateScreenPositions();
        };

        cesiumWidget.scene.camera.changed.addEventListener(cameraMoveListener);

        handlerRef.current.setInputAction((movement: any) => {
            const pickedObject = cesiumWidget.scene.pick(movement.endPosition);

            if (
                !pickedObject ||
                activeObject !== null ||
                pickedObject.primitive instanceof GroundPrimitive
            ) {
                if (currentHoveredIndexRef.current !== null) {
                    const prevBillboard = pointsRef.current?._billboards!.find(
                        (b: CesiumBillboard) => b.id.id === currentHoveredIndexRef.current,
                    );
                    if (prevBillboard) {
                        prevBillboard.show = true;
                    }
                    currentHoveredIndexRef.current = null;

                    if (activeObject) return;
                    setHoveredBillboards([]);
                }
                return;
            }

            const index = pickedObject.id;
            if (!index) return;

            const billboard = pointsRef.current?._billboards.find(
                (b: CesiumBillboard) => b.id.id === index.id,
            );

            if (billboard && currentHoveredIndexRef.current !== index.id) {
                if (currentHoveredIndexRef.current !== null) {
                    const prevBillboard = pointsRef.current?._billboards.find(
                        (b: CesiumBillboard) => b.id.id === currentHoveredIndexRef.current,
                    );
                    if (prevBillboard) {
                        prevBillboard.show = true;
                    }
                }

                billboard.show = false;

                const billboardPosition = billboardPositionsRef.current.find(
                    (b) => b.data.id === billboard.id.id,
                );

                if (billboardPosition) {
                    const screenPosition = cesiumWidget.scene.cartesianToCanvasCoordinates(
                        billboardPosition.position,
                        new Cartesian2(),
                    );

                    setHoveredBillboards([
                        {
                            index: index.id,
                            screenPosition,
                            data: billboardPosition.data,
                            lockedPosition: billboardPosition.position,
                            isExpanded: false,
                        },
                    ]);

                    currentHoveredIndexRef.current = index.id;
                }
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);

        return () => {
            cesiumWidget.scene.camera.changed.removeEventListener(cameraMoveListener);
            if (handlerRef.current) {
                handlerRef.current.destroy();
            }
        };
    }, [cesiumWidget, activeObject]);

    useHandlePrimitiveClick(cesiumWidget, resetClusters, setSelectedCluster, myClusters);

    // Reset clusters from object bar click
    useResetActiveCluster(
        cesiumWidget,
        selectedCluster,
        setSelectedCluster,
        resetClusters,
        setActiveObject,
        setHoveredBillboards,
    );

    const removeRectangleGeometries = useRemoveRectangleGeometries(cesiumWidget);

    // Reset Active object removal
    useResetActiveObject(
        cesiumWidget,
        activeObject,
        setActiveObject,
        resetClusters,
        setHoveredBillboards,
        removeRectangleGeometries,
    );

    useEffect(() => {
        if (!cesiumWidget || selectedCluster === null) return;

        const { camera } = cesiumWidget.scene;

        const onCameraChanged = () => {
            if (flyToInProgress) return;
            setSelectedCluster(null);
            setActiveObject('');
            setHoveredBillboards([]);
            removeRectangleGeometries();
            resetClusters();
        };

        camera.changed.addEventListener(onCameraChanged);

        return () => {
            camera.changed.removeEventListener(onCameraChanged);
        };
    }, [cesiumWidget, selectedCluster, activeObject, flyToInProgress]);

    useEffect(() => {
        if (!cesiumWidget) return () => {};

        const updateBoundsImmediately = () => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds && !isDrawing) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            } else if (!bounds && !isDrawing) {
                setSearchBounds(convertBoundingBox(SEARCH_BOUNDS));
            }
        };

        cesiumWidget.scene.camera.changed.addEventListener(updateBoundsImmediately);

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.camera.changed.removeEventListener(updateBoundsImmediately);
            }
        };
    }, [cesiumWidget, setViewBounds, setSearchBounds, isDrawing]);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
            }}
        >
            {hoveredBillboards.map((hoveredBillboard) => (
                <EvoPoint
                    key={hoveredBillboard.data.id}
                    billboard={hoveredBillboard.data}
                    position={hoveredBillboard.lockedPosition}
                    scene={cesiumWidget!.scene}
                    cesiumWidget={cesiumWidget!}
                    showActiveObject={showActiveObject}
                />
            ))}
            {showActiveObject && activeObject && !hoveredBillboards.length && (
                <EvoPoint
                    key={activeObject.object_id}
                    billboard={activeObject}
                    position={Cartesian3.fromDegrees(
                        findBoxCenter(activeObject.geojson_bounding_box!.coordinates[0]).lng,
                        findBoxCenter(activeObject.geojson_bounding_box!.coordinates[0]).lat,
                        0,
                    )}
                    scene={cesiumWidget!.scene}
                    cesiumWidget={cesiumWidget!}
                    showActiveObject={showActiveObject}
                />
            )}
            {selectedCluster && (
                <ClusterInfo
                    cluster={selectedCluster}
                    scene={cesiumWidget!.scene}
                    cesiumWidget={cesiumWidget!}
                />
            )}
        </div>
    );
};
