import { checkIsAdmin } from '@local/login/dist/util/checkIsAdmin';
import {
    DockableContainer,
    DockPosition,
} from '@local/web-design-system/dist/components/DockableContainer';
import { PropertiesHeader } from '@local/web-design-system/dist/components/PropertiesHeader';
import { MeasurementDialog } from '@local/webviz/dist/components/Measurement';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import { NEW_COLORMAP } from '@local/webviz/dist/components/Properties/Colormaps/Colormap.constants';
import { ColormapControlDialog } from '@local/webviz/dist/components/Properties/Colormaps/ColormapControlDialog/ColormapControlDialog';
import {
    PROPERTIES_TITLE,
    PropertiesDialog,
} from '@local/webviz/dist/components/Properties/PropertiesDialog';
import type { ColormapControlState } from '@local/webviz/dist/components/Properties/PropertiesDialog/PropertiesDialog.types';
import { SelectionContents, SelectionPanel } from '@local/webviz/dist/components/Selection';
import { Settings, SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import type { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import {
    SLICE,
    DockableSliceDialog as SliceDialog,
} from '@local/webviz/dist/components/Slice/SliceDialog';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import { createSnapshotToUpdateColorMap } from '@local/webviz/dist/context/snapshots';
import { parsePrefixUid } from '@local/webviz/dist/utilities';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSelectedObjectsAttributes } from 'src/hooks/useSelectedObjectsAttributes';
import { useSelectionKeyValues } from 'src/hooks/useSelectionKeyValues';
import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { getColormapLoadingStatusById } from 'src/store/colormap/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getAllActiveColormapIds,
    getAttributeColormapAssociationsById,
    getColormapControlData,
    getTreeItemsByIds,
    loadedObjectById,
    orientationVisible,
    scalebarVisible,
    selectionListScenePanel,
} from 'src/store/visualization/selectors';
import {
    updateColormapControl,
    updateObjectActiveColormap,
    updateObjectLegendShown,
    updateOverlays,
} from 'src/store/visualization/visualizationSlice';

import { DOCK_THRESHOLD } from '../constants';
import { useAttributeColormapAssociation } from '../context/hooks/useAttributeColormapAssociation';
import { useColormapCreator } from '../context/hooks/useColormapCreator';
import { useLegends } from '../context/hooks/useLegends';
import {
    getObjectIdFromViewId,
    getViewIdFromObjectId,
} from '../context/snapshots/generateSnapshot';
import type { DockableDialogProps } from './DockableDialogs.types';
import { DockedWindows } from './DockableDialogs.types';

export function DockableSliceDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <SliceDialog
            header={{ title: SLICE, onClose }}
            type={DockedWindows.Slice}
            dockPosition={DockPosition.UPPER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        />
    );
}

export function DockableMeasureDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <DockableContainer
            header={{ title: MEASUREMENT_TITLE, onClose }}
            type={DockedWindows.Measure}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <MeasurementDialog />
        </DockableContainer>
    );
}

export function DockableSettingsDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const isOrientationChecked = useAppSelector(orientationVisible);
    const isScalebarChecked = useAppSelector(scalebarVisible);
    const dispatch = useAppDispatch();
    const updateOverlaysDispatch = (overlays: Partial<SettingsVisualizationOverlays>) =>
        dispatch(updateOverlays(overlays));
    const [numberOfObjects, setNumberOfObjects] = useState(0);
    const { useXyzListener } = useBaseXyz();
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setNumberOfObjects(plotViews.length);
    });

    return (
        <DockableContainer
            type={DockedWindows.Settings}
            header={{ title: SETTINGS_TITLE, onClose }}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <Settings
                isOrientationChecked={isOrientationChecked}
                isScalebarChecked={isScalebarChecked}
                numberOfObjects={numberOfObjects}
                updateOverlays={updateOverlaysDispatch}
            />
        </DockableContainer>
    );
}
export function DockablePropertiesDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const dispatch = useAppDispatch();
    const { evouiColormapsSaving } = useFlags();
    const params = useParams();

    const isAdmin = checkIsAdmin();
    const { data: workspace } = fetchWorkSpaceData(params, isAdmin);
    const isViewer = workspace?.current_user_role === 'viewer';

    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const objectId = parsePrefixUid(selectedObjectIds[0] ?? '');
    const attributeAssociationMap = useAppSelector(getAttributeColormapAssociationsById(objectId));

    const { firstSelectedTreeItem, attributes } = useSelectedObjectsAttributes(selectedObjectIds);
    const selectedTreeItems = useAppSelector(getTreeItemsByIds(Object.values(selectedObjectIds)));

    const selectedViewIds = selectedTreeItems.map((selectedTreeItem) =>
        getViewIdFromObjectId(selectedTreeItem?.treeId, selectedTreeItem?.schema ?? ''),
    );

    const multipleSelected = selectedViewIds.length > 1;
    const showHeaderAdornment = !multipleSelected && firstSelectedTreeItem?.name;
    const showAddColormap = evouiColormapsSaving && !isViewer;

    const handleAttributeColormapAssociations = useAttributeColormapAssociation();

    const colormapLoadingStatus = useAppSelector(getColormapLoadingStatusById(objectId));

    const onNewColormapClicked = (colormapControlProps: ColormapControlState) => {
        dispatch(updateColormapControl(colormapControlProps));
    };

    const onAssociatedColormapSelected = (colormapId: string) => {
        dispatch(updateObjectActiveColormap({ objectId: selectedObjectIds[0], colormapId }));
    };

    const [disableAttributeColormapControl, setDisableAttributeColormapControl] = useState(false);
    const colormapControlState = useAppSelector(getColormapControlData);
    useEffect(() => {
        setDisableAttributeColormapControl(!!colormapControlState.viewId);
    }, [colormapControlState.viewId]);

    const allActiveColormapIds = useAppSelector(getAllActiveColormapIds);

    const legends = useLegends();
    function updateObjectLegendsDispatch() {
        dispatch(
            updateObjectLegendShown({
                objectIds: selectedObjectIds,
            }),
        );
    }

    return (
        <DockableContainer
            header={{
                title: PROPERTIES_TITLE,
                headerAdornment: showHeaderAdornment && (
                    <PropertiesHeader
                        name={firstSelectedTreeItem.name}
                        Icon={SchemaIcon(firstSelectedTreeItem.schema)}
                    />
                ),
                onClose,
            }}
            type={DockedWindows.Properties}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <PropertiesDialog
                selectedViewIds={selectedViewIds}
                attributeOptions={{ attributes, attributeAssociationMap }}
                colormapOptions={{
                    showAddColormap,
                    onColormapReload: () => handleAttributeColormapAssociations(objectId),
                    colormapLoadingStatus,
                    onNewColormapClicked,
                    disableAttributeColormapControl,
                    onAssociatedColormapSelected,
                    activeColormaps: allActiveColormapIds,
                }}
                legendOptions={{
                    legends,
                    onLegendToggle: updateObjectLegendsDispatch,
                }}
                showEmptyMessage={!firstSelectedTreeItem?.name}
            />
        </DockableContainer>
    );
}

export function DockableSelectionPanel() {
    const { getEntityState } = useBaseXyz();
    const { selectionState, unselect } = useSelection();

    const viewId = selectionState?.viewId ?? '';
    const selectionKeyValues = useSelectionKeyValues(viewId);

    const viewState = getEntityState(viewId);

    const objectId = getObjectIdFromViewId(viewId);
    const selectedObject = useAppSelector(loadedObjectById(objectId));
    if (!selectedObject) return null;
    const { name, schema } = selectedObject;

    if (!selectionState?.position || !viewState || !selectedObject) {
        return null;
    }

    const Icon = SchemaIcon(schema);

    const stylePosition = {
        bottom: 16,
        left: 8,
    };

    return (
        <DockableContainer type={DockedWindows.SelectionPanel} stylePosition={stylePosition}>
            <SelectionPanel
                title={name}
                Icon={Icon}
                selectionState={selectionState}
                unselect={unselect}
            >
                <SelectionContents selectionKeyValues={selectionKeyValues} />
            </SelectionPanel>
        </DockableContainer>
    );
}

export function DraggableColormapControlDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const colormapControlData = useAppSelector(getColormapControlData);

    const { createColormap } = useColormapCreator();
    const { setStateFromSnapshot } = useBaseXyz();

    const {
        viewId,
        selectedColorMapId,
        selectedAttributeId,
        attributeKey,
        attributeName,
        existingColormapNames,
        isLoading,
        selectedObjectName,
    } = colormapControlData;

    const onCancel = () => {
        // revert back to last selected colormap for attribute
        const snapshot = createSnapshotToUpdateColorMap(selectedColorMapId, selectedAttributeId);
        setStateFromSnapshot(snapshot);

        onClose();
    };

    return (
        <DockableContainer
            defaultPosition={defaultPosition}
            header={{ title: NEW_COLORMAP, onClose: onCancel }}
            type={DockedWindows.ColormapControl}
            preventDocking
            autoWidth
            autoHeight
        >
            <ColormapControlDialog
                onClose={onClose}
                selectedObjectName={selectedObjectName ?? ''}
                viewId={viewId}
                selectedAttributeId={selectedAttributeId}
                attributeKey={attributeKey}
                attributeName={attributeName}
                existingColormapNames={existingColormapNames}
                onSave={createColormap}
                showSkeleton={isLoading}
            />
        </DockableContainer>
    );
}
