import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { checkIsAdmin } from '@local/login';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useListFilesQuery } from 'src/apiClients/enhancedFileMiddleware';
import type { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { PageContent } from 'src/components/pageContent/PageContent';
import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { SearchField } from 'src/components/searchField/SearchField';
import { WorkspaceContext, useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileDragAndDrop } from 'src/hooks/useFileDragAndDrop';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { usePagination } from 'src/hooks/usePagination';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { Sidebar } from 'src/pages/workspaceFilesPage/sidebar/Sidebar';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import { NotFound } from 'src/pages/workspacePage/WorkspaceNotFound';
import {
    FILES_CONTENT_NO_CONTENT,
    FILES_CONTENT_NO_CONTENT_DESC,
    SEARCH_LABEL,
    SEARCH_PLACEHOLDER,
    UPLOAD_FILES,
} from 'src/strings';
import {
    WORKSPACE_FILES_BREADCRUMB,
    workspaceListingWithLinkBreadcrumb,
    workspacesPath,
} from 'src/utils/breadcrumbs';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { fileDefinition } from '../workspacePage/FieldRowDefinitions';
import { FileActions } from './fileActions/FileActions';
import { ProgressPanel } from './ProgressPanel';
import { UploadFilesButton } from './UploadFilesButton';
import { useStyles } from './WorkspaceFilesPage.styles';

interface FilesListProps {
    files: ListFile[];
    selectedFile: string | undefined;
    isLoading: boolean;
    fields?: FieldDefinition<any>[];
    handleSelectFile: (file: ListFile | undefined) => void;
    isEditor: boolean;
}

const FilesList = ({
    isLoading,
    selectedFile,
    files,
    fields = fileDefinition,
    handleSelectFile,
    isEditor,
}: FilesListProps) => {
    const theme = useTheme();

    if (isLoading) {
        return (
            <PaginatedList
                header={<TableHeader fields={fields} hasActions hasDragAndDrop={isEditor} />}
                content={<TableSkeleton rows={4} columns={fields.length} />}
            />
        );
    }

    if (!isLoading && !files?.length) {
        return (
            <EmptyState
                title={FILES_CONTENT_NO_CONTENT}
                message={FILES_CONTENT_NO_CONTENT_DESC}
                image={<NotFoundSvg />}
                sx={{
                    paddingTop: theme.spacing(8),
                    paddingBottom: theme.spacing(14),
                    width: '50%',
                    margin: 'auto',
                }}
            />
        );
    }

    return (
        <PaginatedList
            header={<TableHeader fields={fields} hasActions hasDragAndDrop={isEditor} />}
            content={files.map((file) => {
                const isSelected = selectedFile === file.file_id;
                const handleClick = () => handleSelectFile(isSelected ? undefined : file);

                return (
                    <ListItem
                        key={file.file_id}
                        item={file}
                        fields={fields}
                        clickHandler={handleClick}
                        actions={<FileActions file={file} />}
                        selected={isSelected}
                        automation-id={`file-row-${file.file_id}-actions`}
                    />
                );
            })}
            isEmpty={isLoading ? false : files.length === 0}
            containerSx={{ height: 'calc(100vh - 360px)', overflowY: 'hidden' }}
            sx={{ overflowY: 'auto', tableLayout: 'fixed' }}
            stickyHeader
        />
    );
};

export const WorkspaceFilesPage = ({ tabs }: { tabs: JSX.Element }) => {
    const params = useParams();
    const { classes } = useStyles();
    const [id, setID] = usePersistedState('id');
    const { evouiWorkspaceFiles } = useFlags();
    const {
        page,
        pageSize,
        searchTerm,
        handleSetPage,
        handlePreviousPage,
        handlePageSizeChange,
        handleSearch,
    } = usePagination();
    const { setSidebarContent } = useSidebarContext();
    const contextValue = useWorkspaceContext();

    const isAdmin = checkIsAdmin();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { isOpen: isUploadContainerOpen } = useFileUploadContainer({ workspaceId });

    const isEditor = contextValue.workspaceUserRole
        ? hasRoleOrHigher(contextValue.workspaceUserRole, 'editor')
        : false;

    const { ...dragProps } = useFileDragAndDrop(isEditor);

    const {
        data: workspace,
        isLoading: isLoadingWorkspace,
        isError: isErrorWorkspace,
    } = fetchWorkSpaceData(params, isAdmin);

    const {
        data: files,
        isFetching: isLoadingFiles,
        isError: isErrorFiles,
    } = useListFilesQuery({
        organisationId: orgId,
        workspaceId,
        limit: pageSize,
        offset: page * pageSize,
        ...(searchTerm !== '' && { fileName: searchTerm }),
    });

    useEffect(() => {
        setDocumentTitle(workspace?.name ?? '');
    }, [workspace?.name]);

    const handleSelectFile = (file: ListFile | undefined) => {
        setID(file ? file.file_id : undefined);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    const { setBreadcrumbs } = useBreadcrumbs();

    useEffect(() => {
        if (!isLoadingWorkspace && workspace) {
            const segments: BreadcrumbSegment[] = [
                ...workspaceListingWithLinkBreadcrumb(params),
                {
                    name: workspace.name,
                    path: `${workspacesPath(params)}/${params.hubCode}/${params.workspaceUuid}/${WORKSPACES_OVERVIEW_PAGE}`,
                },
                { name: WORKSPACE_FILES_BREADCRUMB },
            ];
            setBreadcrumbs(segments);
        }
    }, [isLoadingWorkspace]);

    useEffect(() => {
        if (workspace) {
            const selectedFile = id ?? undefined;
            setSidebarContent(
                <WorkspaceContext.Provider value={contextValue}>
                    <Sidebar selectedFile={selectedFile} />
                </WorkspaceContext.Provider>,
            );
        }

        return () => {
            // Clear the sidebar when leaving the page
            setSidebarContent(undefined);
        };
    }, [id, workspaceId, workspace, contextValue]);

    // If the user ends up on an empty page, take them back a page. This can happen when you delete the last file
    // on a page.
    useEffect(() => {
        if (files?.files && files.files.length === 0 && page > 0) {
            handlePreviousPage();
        }
    }, [files, page]);

    if (!evouiWorkspaceFiles) {
        return null;
    }

    if (
        isErrorWorkspace ||
        isErrorFiles ||
        (!isLoadingWorkspace && !isLoadingFiles && !workspace)
    ) {
        return <NotFound />;
    }
    return (
        <PageContent
            titleActions={tabs}
            pageTitle={workspace?.name ?? ''}
            pageTitleLoading={isLoadingWorkspace}
        >
            <Stack direction="column" sx={{ width: '100%' }}>
                <Grid gap={2} container direction="row" flexWrap="nowrap" alignItems="center">
                    <TablePagination
                        page={page}
                        component="div"
                        labelRowsPerPage="Show"
                        rowsPerPage={pageSize}
                        count={files?.total ?? 0}
                        onPageChange={handleChangePage}
                        classes={{ toolbar: classes.pagination }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                        sx={{
                            paddingLeft: '16px',
                            fontSize: '10px',
                            /* override MUI css style */
                            '& .MuiTablePagination-displayedRows': {
                                fontSize: '10px',
                            },
                        }}
                    />
                    <Grid flexGrow={1} />
                    <Grid item>
                        <SearchField
                            color="secondary"
                            variant="outlined"
                            sx={{ maxWidth: '220px' }}
                            onSearchCallBack={handleSearch}
                            placeholder={SEARCH_PLACEHOLDER}
                            automation-id="workspace-files-search"
                            InputProps={{ sx: { maxHeight: '40px' } }}
                            userAction={UserActionWorkspace.WORKSPACE_FILES_SEARCH}
                            userActionCategory={CategoriesWorkspace.WORKSPACE_FILES_PAGE}
                            label={SEARCH_LABEL}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <UploadFilesButton
                        buttonText={UPLOAD_FILES}
                        disabled={isUploadContainerOpen}
                        sx={{ height: '36px', width: '158px' }}
                    />
                </Grid>
                <Stack
                    {...dragProps}
                    spacing={3}
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <div className={classNames([classes.table])}>
                        <FilesList
                            handleSelectFile={handleSelectFile}
                            selectedFile={id}
                            files={files?.files ?? []}
                            fields={fileDefinition}
                            isLoading={isLoadingFiles || isLoadingWorkspace}
                            isEditor={isEditor}
                        />
                    </div>
                    {isUploadContainerOpen && evouiWorkspaceFiles && (
                        <ProgressPanel workspaceId={workspaceId} />
                    )}
                </Stack>
            </Stack>
        </PageContent>
    );
};
