import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useEnhancedUpdateObjectByIdMutation } from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import type { SerializedError } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_DIALOG_CONTENT,
    RESTORE_DIALOG_TITLE,
    RESTORE_DIALOG_WARNING,
    RESTORE_TOAST_FAILURE,
    RESTORE_TOAST_SUCCESS,
    VIEW_RESTORED_OBJECT,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { recycledObjectDefinition } from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from '../workspacePage/workspaceContent/ObjectRow.styles';

const RestoreAction = ({ onRestore, canRestore }: RecycleActionProps) => (
    <Grid
        gap={2}
        container
        alignItems="center"
        justifyContent="center"
        item
        xs
        automation-id="object-row-restore-action"
    >
        <IconButton
            disabled={!canRestore}
            onClick={onRestore}
            color="secondary"
            automation-id="object-row-restore-button"
        >
            <FileRestoreIcon fontSize="small" />
        </IconButton>
    </Grid>
);

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
}

interface RecycledObjectRowProps {
    object: ListedObject;
    canRestore: boolean;
}

interface ExtendedError extends SerializedError {
    status?: number;
}

export const RecycledObjectRow = ({ object, canRestore }: RecycledObjectRowProps) => {
    const params = useParams();
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [updateObjectsById] = useEnhancedUpdateObjectByIdMutation();
    const [restoreDialogToggle, setRestoreDialogToggle] = useState(false);
    const navigate = useNavigate();

    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const onRestore = async (confirm: boolean | null) => {
        setRestoreDialogToggle(false);
        if (confirm) {
            const response = await updateObjectsById({
                orgId,
                workspaceId,
                objectId: object.object_id,
                deleted: false,
                geoscienceObject: null,
            });
            const responseError = response?.error as ExtendedError;
            const status = responseError?.status;
            if (status === 303 || !responseError) {
                addMessage({
                    message: RESTORE_TOAST_SUCCESS,
                    severity: 'success',
                    inlineChildren: true,
                    action: (
                        <Grid container alignItems="center">
                            <Button
                                sx={{ whiteSpace: 'nowrap' }}
                                onClick={() => navigate('../overview')}
                                className={classes.recycledToastButtonStart}
                            >
                                {VIEW_RESTORED_OBJECT}
                            </Button>
                        </Grid>
                    ),
                });
            } else {
                addMessage({
                    message: RESTORE_TOAST_FAILURE,
                    severity: 'error',
                });
            }
        }
    };

    interface ConfirmRestoreDialogProps {
        open: boolean;
        onClose: (confirm: boolean) => void;
    }

    const ConfirmRestoreDialog = ({ open, onClose }: ConfirmRestoreDialogProps) => (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle automation-id="dialog-title">{RESTORE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${RESTORE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                    <br />
                    {RESTORE_DIALOG_WARNING}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    onClick={() => onClose(false)}
                    variant="outlined"
                    automation-id="dialog-cancel-button"
                >
                    {RESTORE_DIALOG_CANCEL}
                </Button>
                <Button
                    onClick={() => onClose(true)}
                    variant="contained"
                    automation-id="dialog-confirm-button"
                >
                    {RESTORE_DIALOG_CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <ListItem
                item={object}
                key={object.object_id}
                fields={recycledObjectDefinition}
                actions={
                    <RestoreAction
                        onRestore={() => setRestoreDialogToggle(true)}
                        canRestore={canRestore}
                    />
                }
                hasDisabledState
            />

            <ConfirmRestoreDialog open={restoreDialogToggle} onClose={onRestore} />
        </>
    );
};
